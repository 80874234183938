import { ListingTileData, SearchParameters } from '../dtos/businessesForSale';
import axios, { AxiosInstance } from 'axios';

import { BrokerDTO } from '../dtos/broker';
import { Listing } from '../dtos/listingCreation';
import { PicturePath } from '../dtos/uploadImage';

export const getListing = async (params: { listingid: string; axiosMid: AxiosInstance }): Promise<Listing> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `listing/${params.listingid}`,
  });
  return res.data;
};

export const deleteListing = async (params: { listingid: string; axiosMid: AxiosInstance }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'DELETE',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `listing/${params.listingid}`,
  });
  return res.data;
};

export const createListing = async (params: { listing: Listing; axiosMid: AxiosInstance }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/create',
    data: params.listing,
  });
  return res.data;
};

export const updateListing = async (params: {
  listingid: string;
  listing: Listing;
  axiosMid: AxiosInstance;
}): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',

    url: `listing/update/${params.listingid}`,
    data: params.listing,
  });
  return res.data;
};

export const publishListing = async (params: { listingid: string; axiosMid: AxiosInstance }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'PUT',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: `listing/publish/${params.listingid}`,
  });
  return res.data;
};

export const getUploadUrl = async (params: { axiosMid: AxiosInstance; picturePath: PicturePath }): Promise<string> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/uploadUrl',
    params: {
      picture_path: params.picturePath,
    },
  });
  return res.data;
};

export const postImage = async (data: { file: File; secureUrl: string }): Promise<string> => {
  const res = await axios.put(data.secureUrl, data.file, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const searchListings = async (params: {
  searchParameters: SearchParameters;
  pageNumber: number;
}): Promise<{ tile_list: ListingTileData[]; total: number }> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/search',
    params: { number_of_listing: 8, page: params.pageNumber },
    data: params.searchParameters,
  });
  return res.data;
};

export const searchListingsAlerts = async (params: {
  pageNumber: number;
  targetedRegions: number[];
  timestamp: number;
}): Promise<{ listing_list: ListingTileData[]; total: number }> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/alert_listings',
    params: {
      number_of_listing: 8,
      page: params.pageNumber,
      timestamp: params.timestamp,
    },
    data: params.targetedRegions,
  });
  return res.data;
};

export const searchMyListings = async (params: {
  pageNumber: number;
  axiosMid: AxiosInstance;
}): Promise<{ tile_list: Listing[]; total: number }> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/my_listings',
    params: { number_of_listing: 10, page: params.pageNumber },
  });
  return res.data;
};

export const getBrokerFromListing = async (params: {
  listingid: string;
  axiosMid: AxiosInstance;
}): Promise<BrokerDTO> => {
  const res = await params.axiosMid({
    method: 'GET',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/broker_by_listing/',
    params: {
      listing_id: params.listingid,
    },
  });
  return res.data;
};

export const getCarouselListing = async (params: {
  regions?: number[];
  industries?: number[];
  numberOfListings: number;
}): Promise<Listing[]> => {
  const res = await axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_LISTING_MANAGEMENT_BASE_URL || 'http://127.0.0.1:/',
    url: 'listing/carousel',
    params: {
      number_of_listings: params.numberOfListings,
    },
    data: {
      regions: params.regions || [],
      industries: params.industries || [],
    },
  });
  return res.data;
};
