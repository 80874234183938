import * as messageService from '../../../services/MessageService';
import * as userService from '../../../services/UserService';
import { NavLink } from 'react-router-dom';
import { ButtonSmall, ButtonStatus } from '../../../common/button/ButtonSmall';
import { Card, Form, Input, InputNumber, Modal, Spin } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import React, { useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FavoriteButton } from '../../businessesForSale/listingTile/FavoriteButton';
import { Image } from 'antd';
import { LocaleContext } from '../../../router/Router';
import { SellingDetailsData } from '../../../dtos/listingCreation';
import TextArea from 'antd/lib/input/TextArea';
import { UserContext } from '../../../contexts/UserContext';
import { getBrokerFromListing } from '../../../services/ListingService';
import { privateRoutes, publicRoutes } from '../../../router/routes';
import styled from 'styled-components';
import { useAxiosMiddleware } from '../../../services/useAxiosMiddleware';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ContactSeller = (props: {
  selleingDetails: SellingDetailsData;
  id: string | undefined;
  isSeller: boolean | undefined;
}): JSX.Element => {
  const { t } = useTranslation(['fullListing', 'common']),
    { tokenErrorHandler } = useContext(UserContext),
    axiosMiddleware = useAxiosMiddleware(),
    { data } = useQuery(['getUserData'], () => userService.getUserProfile(axiosMiddleware)),
    [contactForm] = useForm(),
    { locale } = useContext(LocaleContext),
    navigate = useNavigate();

  useEffect(() => {
    contactForm.setFieldsValue({
      name: data?.first_name && data?.last_name && data?.first_name + ' ' + data?.last_name,
      email: data?.email,
    });
  }, [data]);

  const formatPhoneNumber = (value: string | undefined) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value || '';
  };

  const sendMessageMutation = useMutation(messageService.sendMessage, {
    onSuccess: () => {
      Modal.success({
        content: t('fullListing:contact.success'),
        onOk: () => navigate(privateRoutes.myMessages(locale), { state: { isReceived: false } }),
      });
    },
    onError: tokenErrorHandler,
  });

  const onFinish = (target: { email: string; message: string; name: string; phone: number }) => {
    sendMessageMutation.mutate({
      email: target.email,
      message: target.message,
      name: target.name,
      phone: formatPhoneNumber(target.phone.toString()),
      axiosMid: axiosMiddleware,
      listingId: props.id || '',
    });
  };

  const brokerQuery = useQuery(
    ['getBrokerFromListing'],
    () => getBrokerFromListing({ listingid: props.id || '', axiosMid: axiosMiddleware }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return (
    <StyledCard>
      <Spin size={'large'} spinning={sendMessageMutation.isLoading}>
        {brokerQuery.data && (
          <>
            <h4>{t('fullListing:contact.presentedBy')}</h4>

            <BrokerSection>
              <div>
                <StyledProfile src={brokerQuery.data.profile_picture} preview={false} />
              </div>
              <StyledColumn>
                <div>
                  <h6>
                    <NavLink to={publicRoutes.mainpage(locale) + 'brokerProfile/' + brokerQuery.data._id}>
                      {brokerQuery.data.first_name} {brokerQuery.data.last_name}
                    </NavLink>
                  </h6>
                  <ContactDiv>
                    <IconSpan>
                      <MailOutlined />
                    </IconSpan>
                    <PContact>{brokerQuery.data.broker_email}</PContact>
                  </ContactDiv>
                  {brokerQuery.data.broker_phone_number && (
                    <ContactDiv>
                      <IconSpan>
                        <PhoneOutlined />
                      </IconSpan>
                      <PContact>{formatPhoneNumber(brokerQuery.data.broker_phone_number.toString())}</PContact>
                    </ContactDiv>
                  )}
                </div>
                <div>
                  <StyledLogo src={brokerQuery.data.company_logo} preview={false} />
                </div>
              </StyledColumn>
            </BrokerSection>
          </>
        )}
        <h4>{t('fullListing:contact.title')}</h4>
        <Form
          form={contactForm}
          disabled={props.isSeller}
          onFinish={onFinish}
          layout={'vertical'}
          requiredMark={'optional'}
        >
          <Form.Item
            name={'name'}
            label={t('fullListing:contact.name')}
            rules={[{ required: true, message: t('fullListing:contact.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'email'}
            label={t('fullListing:contact.email')}
            rules={[{ required: true, message: t('fullListing:contact.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'phone'}
            label={t('fullListing:contact.phone')}
            rules={[
              { required: true, message: t('fullListing:contact.required') },
              {
                type: 'number',
                min: 1111111111,
                max: 9999999999,
                message: t('fullListing:contact.phoneError'),
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} formatter={formatPhoneNumber} />
          </Form.Item>
          <Form.Item
            name={'message'}
            label={t('fullListing:contact.message')}
            rules={[{ required: true, message: t('fullListing:contact.required') }]}
          >
            <TextArea size="large" rows={8} disabled={props.isSeller} />
          </Form.Item>
          <ButtonSmall
            status={ButtonStatus.PRIMARY}
            block={true}
            disabled={props.isSeller}
            icon={<MailOutlined />}
            text={t('fullListing:contact.button')}
          />
        </Form>
        <br />
        <ButtonSmall
          status={ButtonStatus.TERTIARY}
          block={true}
          text={
            <Center>
              <FavoriteButton listingId={props.id || ''} />
            </Center>
          }
        />
        {(props.selleingDetails.email_contact || props.selleingDetails.telephone_contact) && (
          <>
            <br />
            <h4>{t('fullListing:contact.contact')}</h4>
            <PNopad>
              {t('fullListing:contact.altName')}
              {props.selleingDetails.first_name_contact}
              {'\n'}
              {props.selleingDetails.last_name_contact}
              {props.selleingDetails.email_contact && (
                <>
                  <br />
                  {t('fullListing:contact.altEmail')}
                  {props.selleingDetails.email_contact}
                </>
              )}
              {props.selleingDetails.telephone_contact && (
                <>
                  <br />
                  {t('fullListing:contact.altPhone')}
                  {props.selleingDetails.telephone_contact}
                </>
              )}
            </PNopad>
          </>
        )}
      </Spin>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 432px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 0.2);
  border: 0.5px solid #a2a0a8;
  margin: 0;
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
`;

const PNopad = styled.p`
  margin: 0;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactDiv = styled.div`
  display: flex;
`;

const IconSpan = styled.span`
  font-size: 16px;
  margin-right: 8px;
  span,
  p {
    white-space: nowrap !important;
  }
`;

const PContact = styled.p`
  margin-bottom: 0px;
`;

const StyledProfile = styled(Image)`
  max-width: 100px;
  border-radius: 5px;
`;
const StyledLogo = styled(Image)`
  max-width: 140px;
`;

const BrokerSection = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 32px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
