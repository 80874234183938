import { Col, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { Footer } from '../../components/footer/Footer';
import { HelmetFranchiseFranchisor } from '../../helmets/HelmetFranchiseFranchisor';
import { Image } from 'antd';
import React from 'react';
import { ViewNarrowColumn } from '../../common/views/ViewNarrowColumn';
import entrepreneur from './../../img/Entrepreneur.webp';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const FranchiseFranchisor = () => {
  const { t } = useTranslation('franchiseFranchisor');
  return (
    <ViewNarrowColumn>
      <HelmetFranchiseFranchisor />
      <Row justify="center">
        <Block>
          <Row justify="space-around" align="middle" gutter={[40, 40]}>
            <Col lg={12}>
              <StyledH2>{t('title')}</StyledH2>
              <PBreak>{t('intro')}</PBreak>
              <p>{t('desc')}</p>
              <PBreak>
                <Trans i18nKey={'franchiseFranchisor:contact'} components={{ bold: <strong /> }} />
                <u>
                  <a href="mailto:info@acquizition.biz">info@acquizition.biz</a>
                </u>
              </PBreak>
            </Col>
            <Col lg={12} xl={12} xxl={10}>
              <Image src={entrepreneur} preview={false} alt="Business owner" />
            </Col>
          </Row>
        </Block>
      </Row>
      <Footer />
    </ViewNarrowColumn>
  );
};

const Block = styled.section`
  padding: 4vw;
  max-width: 1800px;
  width: 100%;
`;

const PBreak = styled.p`
  white-space: pre-line;
`;

const StyledH2 = styled.h1`
  font-size: ${theme.fontsize.h2} !important;
  font-weight: bolder;
`;
