import * as paymentService from '../../services/PaymentService';

import { Divider, Modal, Row, Spin } from 'antd';
import { deleteListing, getListing, searchMyListings } from '../../services/ListingService';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useNavigationType, useSearchParams } from 'react-router-dom';

import { AxiosInstance } from 'axios';
import { ListingCard } from './ListingCard';
import { LocaleContext } from '../../router/Router';
import React from 'react';
import { SubscriptionCard } from './SubscriptionCard';
import { UserContext } from '../../contexts/UserContext';
import { ViewCentered } from '../../common/views/ViewCentered';
import { ViewDashboard } from '../../common/views/ViewDashboard';
import { privateRoutes } from '../../router/routes';
import styled from 'styled-components';
import { tCustom } from '../../services/TranslationService';
import { useAxiosMiddleware } from '../../services/useAxiosMiddleware';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const fetchListings = async (pageNumber: number, axiosMid: AxiosInstance) => {
  return searchMyListings({ pageNumber: pageNumber, axiosMid: axiosMid });
};

export const MyListings = (): JSX.Element => {
  const { t } = useTranslation(['common', 'myListings', 'regions']),
    { tokenErrorHandler, userType } = useContext(UserContext),
    { locale } = useContext(LocaleContext),
    axiosMiddleware = useAxiosMiddleware(),
    navigate = useNavigate();

  const navigationType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 0);
  const [prevId, setPrevId] = useState(searchParams.get('prev_id') ? searchParams.get('prev_id') : undefined);

  useEffect(() => {
    setSearchParams({});
    return;
  }, []);

  const { data, fetchNextPage, isFetching, remove, refetch, error } = useInfiniteQuery({
    queryKey: ['getMyListings'],
    queryFn: ({ pageParam = { page: 0 } }) => {
      return fetchListings(pageParam.page, axiosMiddleware);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.tile_list?.length === 0) {
        return undefined;
      }
      return { page: page + 1 };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const observer: React.MutableRefObject<IntersectionObserver | undefined> = useRef();
  const lastListingElementRef = useCallback(
    (node: Element | null) => {
      if (isFetching) return;
      if (typeof observer != 'undefined' && observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data],
  );

  useEffect(() => {
    if (!prevId) {
      remove();
      setPage(0);
      refetch();
    }
    return;
  }, []);

  useEffect(() => {
    if (navigationType === 'POP' && prevId) {
      document.getElementById(prevId.toString())?.scrollIntoView({ behavior: 'auto', block: 'end' });
      setPrevId(undefined);
    }
    return;
  }, [navigationType]);

  const subscriptionContentQuery = useQuery(['getSubscription'], {
    queryFn: () => paymentService.getSubscription({ axiosMid: axiosMiddleware }),
    onError: tokenErrorHandler,
  });

  const deleteMutation = useMutation(deleteListing, {
    onSuccess: () => {
      remove();
      setPage(0);
      refetch();
    },
  });

  const deleteListingAction = (id: string) => {
    Modal.warn({
      content: t('myListings:deleteWarning'),
      onOk: () => deleteMutation.mutate({ listingid: id, axiosMid: axiosMiddleware }),
      closable: true,
    });
  };

  const getListingMutation = useMutation(getListing, {
    onSuccess: (listing) => {
      navigate(privateRoutes.createListing(locale), { state: listing });
    },
    onError: tokenErrorHandler,
  });

  const editListingAction = (id: string) => {
    getListingMutation.mutate({ listingid: id, axiosMid: axiosMiddleware });
  };

  useEffect(() => {
    if (
      !subscriptionContentQuery.isLoading &&
      (subscriptionContentQuery.data === undefined || subscriptionContentQuery.data === null)
    ) {
      navigate(privateRoutes.subscription(locale));
    }
  }, [subscriptionContentQuery.data]);

  return error ? (
    <ViewCentered maxWidth="500px">{<h4>{t('common:serverError')}</h4>}</ViewCentered>
  ) : (
    <ViewDashboard>
      <Spin
        size="large"
        spinning={subscriptionContentQuery.isLoading || getListingMutation.isLoading || deleteMutation.isLoading}
      >
        <StyledFlex>
          <h3>{userType === 'broker' ? t('myListings:titleBroker') : t('myListings:titleOwner')}</h3>
          {subscriptionContentQuery.data && <SubscriptionCard product={subscriptionContentQuery.data} />}
        </StyledFlex>
        <Divider />
        {data?.pages.map((result) => {
          return result.tile_list?.map((tile, arrayIndex) => (
            <div
              id={tile._id}
              ref={
                data.pages?.[data.pages.length - 1]?.tile_list?.[
                  data.pages[data.pages?.length - 1]?.tile_list.length - 1
                ]
                  ? lastListingElementRef
                  : null
              }
              key={arrayIndex}
            >
              <ListingCard
                id={tile._id || ''}
                delete={{ value: t('myListings:deleteListing'), action: () => deleteListingAction(tile._id || '') }}
                edit={{ value: t('myListings:editListing'), action: () => editListingAction(tile._id || '') }}
                view={{ value: t('myListings:viewListing') }}
                title={tCustom(tile.basic_info.listing_title, locale) || ''}
                location={
                  tile.basic_info.region_1?.[0] &&
                  t(`regions:regions.${tile.basic_info.region_1?.[0]}`) +
                    (tile.basic_info.region_1?.[1] ? ', ' + t(`regions:regions.${tile.basic_info.region_1?.[1]}`) : '')
                }
                img={tile.additional_info.picture_primary || ''}
                isPublished={tile.is_published}
                isVerified={tile.is_verified}
                priorityExposure={tile.priority_exposure}
                featured={tile.featured}
                page={page}
              />
            </div>
          ));
        })}
        {!subscriptionContentQuery.isLoading && isFetching && (
          <Center>
            <Spin size="large" />
          </Center>
        )}
        {!isFetching && !getListingMutation.isLoading && !data?.pages?.[0]?.tile_list?.length && (
          <Row justify={'center'}>
            <p>{t('myListings:noListing')}</p>
          </Row>
        )}
      </Spin>
    </ViewDashboard>
  );
};

const StyledFlex = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  flex-flow: wrap;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;
