import { CreditCardFilled, DollarCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { StyledH6, TextDescription } from '../descriptions/TextDescription';

import { IconDescription } from '../descriptions/IconDescription';
import { Image } from 'antd';
import { Listing } from '../../../dtos/listingCreation';
import { LocaleContext } from '../../../router/Router';
import { StyledBox } from '../FullListing';
import { currencyFormatter } from '../fullListingHelper';
import styled from 'styled-components';
import { tCustom } from '../../../services/TranslationService';
import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';

type BasicInfoProps = {
  id: string;
  listing: Listing;
};

export const BasicInfo = (props: BasicInfoProps): JSX.Element => {
  const { t } = useTranslation(['fullListing', 'industries', 'regions']);
  const { locale } = useContext(LocaleContext);
  const fromater = new Intl.NumberFormat('fr-CA');

  const formatPrice = (value: number | undefined): string => {
    if (value === undefined || value === null) {
      return '-';
    } else {
      return fromater.format(Math.floor(value));
    }
  };

  const [isSamllScreen, setIsSamllScreen] = useState(window.innerWidth < 1360);

  const handleResize = () => {
    if (window.innerWidth < 1360) {
      setIsSamllScreen(true);
    } else {
      setIsSamllScreen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.addEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <h3>{tCustom(props.listing.basic_info?.listing_title, locale)}</h3>
      <Image.PreviewGroup>
        <ImgDiv>
          {props.listing.additional_info.video && (
            <iframe
              width={isSamllScreen ? 188 : 284}
              height={isSamllScreen ? 104 : 156}
              src={
                'https://www.youtube.com/embed/' +
                props.listing.additional_info.video.split('/')?.[
                  props.listing.additional_info.video.split('/')?.length - 1
                ]
              }
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          {props.listing.additional_info.picture_primary && (
            <Image
              width={isSamllScreen ? 104 : 156}
              height={isSamllScreen ? 104 : 156}
              src={props.listing.additional_info.picture_primary}
            />
          )}
          {(props.listing.additional_info.pictures_secondary || []).map((image, key) => {
            return <Image key={key} width={isSamllScreen ? 104 : 156} height={isSamllScreen ? 104 : 156} src={image} />;
          })}
        </ImgDiv>
      </Image.PreviewGroup>
      <h4>{t('fullListing:basic_info.title')}</h4>
      <StyledBox>
        <FlexDiv>
          {props.listing.basic_info.asking_price ? (
            <IconDescription
              description={t('fullListing:basic_info.asking_price')}
              content={formatPrice(props.listing.basic_info.asking_price)}
              icon={<CreditCardFilled />}
            />
          ) : (
            <IconDescription
              description={t('fullListing:basic_info.asking_price')}
              content={t('fullListing:basic_info.open_to_offers')}
              icon={<CreditCardFilled />}
            />
          )}
          {props.listing.financial_details.revenue && (
            <IconDescription
              description={t('fullListing:basic_info.revenue')}
              content={currencyFormatter(props.listing.financial_details.revenue, 1)}
              icon={<DollarCircleOutlined />}
            />
          )}
          {props.listing.financial_details.ebitda && (
            <IconDescription
              description={t('fullListing:basic_info.ebitda')}
              content={currencyFormatter(props.listing.financial_details.ebitda, 1)}
              icon={<DollarCircleOutlined />}
            />
          )}
          {props.listing.financial_details.net_income && (
            <IconDescription
              description={t('fullListing:basic_info.net_income')}
              content={currencyFormatter(props.listing.financial_details.net_income, 1)}
              icon={<DollarCircleOutlined />}
            />
          )}

          {props.listing.additional_info.number_employees && (
            <IconDescription
              description={t('fullListing:basic_info.number_employees')}
              content={props.listing.additional_info.number_employees.toString()}
            />
          )}
          {props.listing.additional_info.date_founded && (
            <IconDescription
              description={t('fullListing:basic_info.date_founded')}
              content={props.listing.additional_info.date_founded.toString()}
            />
          )}
        </FlexDiv>
        <FlexDiv>
          {props.listing.basic_info.region_1?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.region_1')}
              content={
                t(`regions:regions.${props.listing.basic_info.region_1?.[0]}`) +
                (props.listing.basic_info.region_1?.[1]
                  ? ',\n' + t(`regions:regions.${props.listing.basic_info.region_1?.[1]}`)
                  : '')
              }
              icon={<PushpinOutlined />}
            />
          )}
          {props.listing.basic_info.region_2?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.region_2')}
              content={
                t(`regions:regions.${props.listing.basic_info.region_2?.[0]}`) +
                (props.listing.basic_info.region_2?.[1]
                  ? ',\n' + t(`regions:regions.${props.listing.basic_info.region_2?.[1]}`)
                  : '')
              }
              icon={<PushpinOutlined />}
            />
          )}
          {props.listing.basic_info.region_3?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.region_3')}
              content={
                t(`regions:regions.${props.listing.basic_info.region_3?.[0]}`) +
                (props.listing.basic_info.region_3?.[1]
                  ? ',\n' + t(`regions:regions.${props.listing.basic_info.region_3?.[1]}`)
                  : '')
              }
              icon={<PushpinOutlined />}
            />
          )}
        </FlexDiv>
        <FlexDiv>
          {props.listing.basic_info.industry_1?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.industries_1')}
              content={
                t(`industries:industries.${props.listing.basic_info.industry_1?.[0]}`) +
                (props.listing.basic_info.industry_1?.[1]
                  ? ',\n' + t(`industries:industries.${props.listing.basic_info.industry_1?.[1]}`)
                  : '')
              }
            />
          )}
          {props.listing.basic_info.industry_2?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.industries_2')}
              content={
                t(`industries:industries.${props.listing.basic_info.industry_2?.[0]}`) +
                (props.listing.basic_info.industry_2?.[1]
                  ? ',\n' + t(`industries:industries.${props.listing.basic_info.industry_2?.[1]}`)
                  : '')
              }
            />
          )}
          {props.listing.basic_info.industry_3?.[0] && (
            <IconDescription
              description={t('fullListing:basic_info.industries_3')}
              content={
                t(`industries:industries.${props.listing.basic_info.industry_3?.[0]}`) +
                (props.listing.basic_info.industry_3?.[1]
                  ? ',\n' + t(`industries:industries.${props.listing.basic_info.industry_3?.[1]}`)
                  : '')
              }
            />
          )}
        </FlexDiv>
        {tCustom(props.listing.basic_info.about_company, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.about_company')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.about_company, locale)!}
          />
        )}
        {tCustom(props.listing.basic_info.competitive_advantages, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.competitive_advantages')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.competitive_advantages, locale)!}
          />
        )}
        {tCustom(props.listing.basic_info.customer_base, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.customer_base')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.customer_base, locale)!}
          />
        )}
        {tCustom(props.listing.basic_info.competitors, locale) && (
          <TextDescription
            title={t('fullListing:basic_info.competitors')}
            // eslint-disable-next-line
            content={tCustom(props.listing.basic_info.competitors, locale)!}
          />
        )}
        {props.listing.basic_info.patent && (
          <TextDescription title={t('fullListing:basic_info.patent')} content={props.listing.basic_info.patent} />
        )}
        {props.listing.basic_info.license && (
          <TextDescription title={t('fullListing:basic_info.license')} content={props.listing.basic_info.license} />
        )}
        {props.listing.additional_info.website && (
          <>
            <StyledH6>
              <u>{t('fullListing:basic_info.website')}</u>
            </StyledH6>
            <PBlue>{props.listing.additional_info.website}</PBlue>
          </>
        )}
      </StyledBox>
    </>
  );
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PBlue = styled.p`
  color: ${theme.color.electricBlue};
`;

const ImgDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 4px;
  margin-bottom: 16px;

  img {
    object-fit: cover !important;
  }
`;
